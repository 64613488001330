import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap';
import './assets/stylesheets/main.scss';
import { updateLatestCaptisStats } from "./src/captis/captis";

export function onClientEntry() {
    window.addEventListener('load', updateLatestCaptisStats)
}

